import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import Timeline from 'components/Timeline';
import Banner from 'components/Banner';

import { HistoryPageComponentProps } from './models';
import './HistoryPageMain.scss';
import './HistoryPageExtends.scss';

const HistoryPage: FC<HistoryPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoHistory: {
      banner,
      timeline,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      url,
      defaultCompositions,
    },
  },
}) => (
  <Layout
    {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="history-page"
  >
    <div className="history-page__wrapper">
      {banner.map(({ properties: { title, ...restBannerProperties } }) => (
        <Banner
          key={`history-page-banner-${title}`}
          url={url}
          title={title}
          {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
        />
      ))}
      {timeline.map(({ properties: timelineProperties }) => (
        <Timeline
          key={`history-page-timeline-${timelineProperties.id}`}
          events={timelineProperties.events?.map(({ properties: eventProperties }) => ({
            ...eventProperties,
            image: eventProperties.localImage,
          }))}
          config={{
            showImagesOnMobile: timelineProperties.isImagesOnMobileVisible,
            firstImagePosition: timelineProperties.isFirstImageOnRight ? 'right' : 'left',
          }}
        />
      ))}
    </div>
  </Layout>
);

export const query = graphql`
  query HistoryPage($url: String = "", $lang: String) {
    umbracoHistory: umbracoHistory(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      timeline {
        properties {
          id
          isFirstImageOnRight
          isImagesOnMobileVisible
          events {
            properties {
              id
              text
              textStyle
              title
              localImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageAlt
              imageStyle
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default HistoryPage;
