import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import TimelineEvent from './TimelineEvent';
import './Timeline.scss';
import { TimelineProps } from './models';

const DEFAULT_CONFIG: TimelineProps['config'] = {
  showImagesOnMobile: true,
  firstImagePosition: 'right',
};

const Timeline: FC<TimelineProps> = ({ events, config, isLazyLoading, className }) => {
  const { showImagesOnMobile, firstImagePosition } = useMemo(
    () => ({
      ...DEFAULT_CONFIG,
      ...config,
    }),
    [config]
  );

  const defineTimelineEventImagePosition = useMemo(
    () => (eventIndex: number) => {
      if (firstImagePosition === 'left') {
        return eventIndex % 2 === 0 ? 'left' : 'right';
      }

      return eventIndex % 2 === 0 ? 'right' : 'left';
    },
    [firstImagePosition]
  );

  return (
    <div className={classnames('timeline', className)}>
      {events
        .filter(({ title, text, image }) => [title, text, image].some((field) => !!field))
        .map((event, index) => (
          <TimelineEvent
            {...event}
            key={`timeline-element-${event.title || event.text || index}`}
            isLazyLoading={typeof isLazyLoading === 'boolean' ? isLazyLoading : index > 0}
            showImagesOnMobile={showImagesOnMobile}
            imagePosition={defineTimelineEventImagePosition(index)}
            imageStyle={event.imageStyle || undefined}
            textStyle={event.textStyle || undefined}
          />
        ))}
    </div>
  );
};

export default Timeline;
